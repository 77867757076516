<template>
  <div>
    <operations-detail-component-global
      :sApiGetOC="sApiGetOC"
      :aHeadersRawMaterials="aHeadersRawMaterials"
      :sTypeItem="1"
      sBackHref="OPGeneral"
      sTextDetail=""
      :toProvider="toProvider"
      :bToProvider="true"
      :itemsBreadcrumbs="itemsBreadcrumbs"
      sTextBtnAdd="Marcar como finalizada"
      sClassBtnAdd="global-btn-header-primary "
      :bAdminPermissions="bAdminOPGeneral"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemsBreadcrumbs: [],
      toProvider: "",
      sApiGetOC: "",
      aHeadersRawMaterials: [
        {
          text: "#",
          sortable: false,
          value: "dNumTransation",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Código de materia",
          sortable: false,
          value: "sCodeRawMaterial",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Materia",
          sortable: false,
          value: "sNameRawMaterial",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Responsable",
          sortable: false,
          value: "sManagerRawMaterial",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Peso",
          sortable: false,
          value: "dWeightRawMaterial",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Merma",
          sortable: false,
          value: "dUnprocessedWeight",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Precio unitario",
          sortable: false,
          align:"end",
          value: "dUnitPriceRawMaterial",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Subtotal",
          sortable: false,
          align:"end",
          value: "dSubtotalRawMaterial",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Acciones",
          value: "accions",
          align: "center",
          class:
            "global-header-table global-header-accions global-col-header-accion",
          cellClass:
            "global-body-table global-body-accions global-col-body-accion",
        },
      ],
    };
  },
  beforeMount() {
    this.setNameBreadcrumbs();
    this.setApiGetOC();
  },
  methods: {
    setNameBreadcrumbs() {
      let aBreadcrumbs = [
        {
          text: "Operaciones",
          disabled: false,
          to: { name: "OPGeneral" },
        },
        {
          text: "Detalle de una operación",
          link: true,
          exact: true,
          disabled: true,
          to: { name: "OPGeneralDetail" },
          params: { id: this.$route.params.idOP },
        },
      ];
      this.itemsBreadcrumbs = aBreadcrumbs;
      this.toProvider = `/home/op-general-detail/${this.$route.params.idOP}/actor-detail-op-general`;
    },
    setApiGetOC() {
      this.sApiGetOC = `${URI}/api/sp/v1/orders/general/`;
    },
  },
  computed: {
    bShowOPGeneral() {
      return this.$store.state.bShowOPGeneral;
    },
    bAdminOPGeneral() {
      return this.$store.state.bAdminOPGeneral;
    },
  },
};
</script>

<style></style>
